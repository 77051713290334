import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function SecondaryButton({ children, onClick = () => {}, ...rest }) {
  const classes = useStyles({ ...theme });

  return (
    <Button
      className={classes.button}
      size="large"
      variant="text"
      color="primary"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default SecondaryButton;
